// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { LuMic } from "react-icons/lu";
// import styled from "styled-components";

// const Button = styled.button`
//   background-color: var(--purple);
//   padding: 0.5rem 1rem;
//   border-radius: 20px;
//   color: var(--white);
//   font-weight: 600;
//   cursor: pointer;
//   transition: all 0.2s;
//   &:hover {
//     transform: scale(1.1);
//   }
//   &:focus {
//     transform: scale(0.9);
//   }
//   @media only screen and (max-width: 40em) {
//     font-size: 1.2rem;
//     &:hover {
//       transform: none;
//     }
//     &:focus {
//       transform: none;
//     }
//   }
// `;

// const AudioRecorder = () => {
//   const [subtitles, setSubtitles] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorder = useRef(null);
//   const audioChunks = useRef([]);

//   useEffect(() => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.ondataavailable = (event) => {
//         audioChunks.current.push(event.data);
//       };
//       mediaRecorder.current.onstop = () => {
//         const blob = new Blob(audioChunks.current, { type: "audio/wav" });
//         setAudioBlob(blob);
//         audioChunks.current = [];
//       };
//     }
//   }, [isRecording]);

//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorder.current = new MediaRecorder(stream);
//     mediaRecorder.current.start();
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     mediaRecorder.current.stop();
//     setIsRecording(false);
//   };

//   const handleSubtitles = async () => {
//     setIsLoading(true);
//     if (!audioBlob) {
//       console.error("No audio recorded.");
//       return;
//     }

//     try {
//       // Create a FormData instance to send the audio data
//       const formData = new FormData();
//       formData.append("audio_file", audioBlob, "audio.wav");

//       const response = await axios.post(
//         "http://localhost:4000/api/transcribe",
//         formData, // Send the FormData instance
//         {
//           headers: {
//             accept: "application/json",
//             "Content-Type": "multipart/form-data", // Set the correct Content-Type
//           },
//           withCredentials: true,
//         }
//       );

//       console.log(response);
//       setIsLoading(false);
//       setSubtitles(response.data);
//     } catch (error) {
//       setIsLoading(false);
//       console.error("Error uploading audio:", error);
//     }
//   };

//   return (
//     <div>
//       {/* <button onClick={isRecording ? stopRecording : startRecording}>
//         {isRecording ? "Stop Recording" : "Start Recording"}
//       </button> */}

//       <Button
//         href="#"
//         whileHover={{ scale: 1.1 }}
//         onClick={isRecording ? stopRecording : startRecording}
//       >
//         <LuMic />
//         {isRecording ? "Stop Recording" : "Start Recording"}
//       </Button>

//       {/* {audioBlob && <button onClick={handleSubtitles}>Get Subtitles</button>} */}

//       {audioBlob && (
//         <Button onClick={handleSubtitles}>
//           {isLoading ? "Loading..." : "Get Subtitles"}
//         </Button>
//       )}

//       {audioBlob && <audio controls src={URL.createObjectURL(audioBlob)} />}
//       {subtitles && <div>{subtitles}</div>}
//     </div>
//   );
// };

// export default AudioRecorder;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { LuMic } from "react-icons/lu";
// import styled from "styled-components";

// const Button = styled.button`
//   background-color: var(--white);
//   padding: 0.7rem 1.5rem;
//   border-radius: 30px;
//   color: var(--white);
//   font-weight: 600;
//   font-size: 1.2rem;
//   cursor: pointer;
//   transition: all 0.3s ease-in-out;
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   border: 2px solid var(--black);
//   background-color: #f0f0f0;
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

//   &:hover {
//     transform: scale(1.1);
//     background-color: #f0f0f0;
//   }

//   &:focus {
//     transform: scale(0.9);
//   }

//   svg {
//     fill: var(--black);
//   }

//   @media only screen and (max-width: 40em) {
//     font-size: 1rem;
//     padding: 0.5rem 1rem;
//     &:hover {
//       transform: none;
//     }
//     &:focus {
//       transform: none;
//     }
//   }
// `;

// const AudioRecorder = () => {
//   const [subtitles, setSubtitles] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorder = useRef(null);
//   const audioChunks = useRef([]);

//   useEffect(() => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.ondataavailable = (event) => {
//         audioChunks.current.push(event.data);
//       };
//       mediaRecorder.current.onstop = () => {
//         const blob = new Blob(audioChunks.current, { type: "audio/wav" });
//         setAudioBlob(blob);
//         audioChunks.current = [];
//       };
//     }
//   }, [isRecording]);

//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorder.current = new MediaRecorder(stream);
//     mediaRecorder.current.start();
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     mediaRecorder.current.stop();
//     setIsRecording(false);
//   };

//   const handleSubtitles = async () => {
//     setIsLoading(true);
//     if (!audioBlob) {
//       console.error("No audio recorded.");
//       return;
//     }

//     try {
//       // Create a FormData instance to send the audio data
//       const formData = new FormData();
//       formData.append("audio_file", audioBlob, "audio.wav");

//       const response = await axios.post(
//         "http://localhost:4000/api/transcribe",
//         formData, // Send the FormData instance
//         {
//           headers: {
//             accept: "application/json",
//             "Content-Type": "multipart/form-data", // Set the correct Content-Type
//           },
//           withCredentials: true,
//         }
//       );

//       console.log(response);
//       setIsLoading(false);
//       setSubtitles(response.data);
//     } catch (error) {
//       setIsLoading(false);
//       console.error("Error uploading audio:", error);
//     }
//   };

//   return (
//     <div>
//       {audioBlob && <audio controls src={URL.createObjectURL(audioBlob)} />}

//       <Button
//         href="#"
//         whileHover={{ scale: 1.1 }}
//         onClick={isRecording ? stopRecording : startRecording}
//       >
//         <LuMic />
//         {isRecording ? "Stop Recording" : "Start Recording"}
//       </Button>

//       {audioBlob && (
//         <Button onClick={handleSubtitles}>
//           {isLoading ? "Loading..." : "Get Subtitles"}
//         </Button>
//       )}

//       {subtitles && <div>{subtitles}</div>}
//     </div>
//   );
// };

// export default AudioRecorder;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { LuMic } from "react-icons/lu";
import styled, { keyframes } from "styled-components";
import { Textarea } from "@chakra-ui/react";

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const PulseCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite;
  z-index: -1;
`;

const Button = styled.button`
  background-color: #fff;
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  color: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: ${(props) => (props.isRecording ? "none" : "2px solid var(--black)")};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 1rem; /* Add gap between buttons */

  &:hover {
    transform: scale(1.1);
    background-color: ${(props) => (props.isRecording ? "#FF6347" : "#f0f0f0")};
  }

  &:focus {
    transform: scale(0.9);
  }

  svg {
    fill: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  }

  @media only screen and (max-width: 40em) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

// const AudioRecorder = () => {
//   const [subtitles, setSubtitles] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const mediaRecorder = useRef(null);
//   const audioChunks = useRef([]);

//   useEffect(() => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.ondataavailable = (event) => {
//         audioChunks.current.push(event.data);
//       };
//       mediaRecorder.current.onstop = () => {
//         const blob = new Blob(audioChunks.current, { type: "audio/wav" });
//         setAudioBlob(blob);
//         audioChunks.current = [];
//       };
//     }
//   }, [isRecording]);

//   const startRecording = async () => {
//     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//     mediaRecorder.current = new MediaRecorder(stream);
//     mediaRecorder.current.start();
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     mediaRecorder.current.stop();
//     setIsRecording(false);
//   };

//   const resetRecording = () => {
//     setIsRecording(false);
//     setAudioBlob(null);
//     setSubtitles(null);
//     setIsLoading(false);
//   };

//   const handleSubtitles = async () => {
//     setIsLoading(true);
//     if (!audioBlob) {
//       console.error("No audio recorded.");
//       return;
//     }

//     try {
//       // Create a FormData instance to send the audio data
//       const formData = new FormData();
//       formData.append("audio_file", audioBlob, "audio.wav");

//       const response = await axios.post(
//         "http://localhost:4000/api/transcribe",
//         formData, // Send the FormData instance
//         {
//           headers: {
//             accept: "application/json",
//             "Content-Type": "multipart/form-data", // Set the correct Content-Type
//           },
//           withCredentials: true,
//         }
//       );

//       console.log(response);
//       setIsLoading(false);
//       setSubtitles(response.data);
//     } catch (error) {
//       setIsLoading(false);
//       console.error("Error uploading audio:", error);
//     }
//   };

//   return (
//     <div>
//       {audioBlob && (
//         <audio
//           controls
//           src={URL.createObjectURL(audioBlob)}
//           style={{ marginBottom: "1rem" }}
//         />
//       )}

//       <div style={{ position: "relative", display: "block" }}>
//         {isRecording && <PulseCircle />}
//         <Button
//           isRecording={isRecording}
//           onClick={isRecording ? stopRecording : startRecording}
//         >
//           <LuMic />
//           {isRecording ? "Stop Recording" : "Start Recording"}
//         </Button>
//       </div>

//       {audioBlob && (
//         <>
//           <Button onClick={handleSubtitles}>
//             {isLoading ? "Loading..." : "Get Subtitles"}
//           </Button>
//           <Button onClick={resetRecording}>Reset</Button>
//         </>
//       )}
//     </div>
//   );
// };

// export default AudioRecorder;

// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import { LuMic } from "react-icons/lu";
// import styled, { keyframes } from "styled-components";
// import { Textarea } from "@chakra-ui/react";

const AudioRecorder = () => {
  const [subtitles, setSubtitles] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  useEffect(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.onstop = async () => {
        const blob = new Blob(audioChunks.current, { type: "audio/wav" });
        setAudioBlob(blob);
        audioChunks.current = [];
        await handleSubtitles(blob); // Call handleSubtitles with the blob
      };
    }
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.start();
      setIsRecording(true);
      console.log("Recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      console.log("Recording stopped");
    } else {
      console.error("No media recorder found");
    }
  };

  const resetRecording = () => {
    setIsRecording(false);
    setAudioBlob(null);
    setSubtitles(null);
    setIsLoading(false);
  };

  const handleSubtitles = async (blob) => {
    setIsLoading(true);
    if (!blob) {
      console.error("No audio recorded.");
      setIsLoading(false);
      return;
    }

    try {
      // Create a FormData instance to send the audio data
      const formData = new FormData();
      formData.append("audio_file", blob, "audio.wav");

      const response = await axios.post(
        "http://localhost:4000/api/transcribe",
        formData, // Send the FormData instance
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data", // Set the correct Content-Type
          },
          withCredentials: true,
        }
      );

      console.log(response);
      setSubtitles(response.data); // Set the subtitles
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {audioBlob && (
        <audio
          controls
          src={URL.createObjectURL(audioBlob)}
          style={{ marginBottom: "1rem" }}
        />
      )}

      <div style={{ position: "relative", display: "block" }}>
        {isRecording && <PulseCircle />}
        <Button
          isRecording={isRecording}
          onClick={isRecording ? stopRecording : startRecording}
        >
          <LuMic />
          {isRecording ? "Stop Recording" : "Start Recording"}
        </Button>
      </div>

      {audioBlob && (
        <>
          {isLoading ? (
            <div style={{ color: "white" }}>Loading...</div>
          ) : (
            <>
              <textarea
                value={subtitles ? subtitles : ""}
                readOnly
                style={{
                  width: "70%",
                  minHeight: "100px",
                  maxHeight: "300px",
                  marginTop: "1rem",
                  resize: "vertical",
                  overflow: "auto",
                }}
              />
              <Button onClick={resetRecording}>Reset</Button>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AudioRecorder;
