
// import React, { useContext, useEffect } from 'react'
// import { UserContext } from '../context/UserContext'
// import { Route, Routes, Navigate } from 'react-router-dom';
// import ChatAI from '../components/aiPage/ChatAI';
// import Signup from '../Pages/signup/Signup';

// export const ProtectedRoute = ({ children }) => {

//     const { user, setUser } = useContext(UserContext);



//     if (!user || !user.googleId) {
//         // If user is not authenticated, redirect to signup
//         return <Navigate replace to="/signup" />;
//     }

//     // If user is authenticated, render the children components
//     return children;
// };



// ProtectedRoute.js


import React, { useContext, useEffect } from 'react'
import { UserContext } from '../context/UserContext'
import { Route, Routes, Navigate } from 'react-router-dom';
import ChatAI from '../components/aiPage/ChatAI';
import Signup from '../Pages/signup/Signup';

export const ProtectedRoute = ({ children }) => {
    const storedUser = localStorage.getItem('user');
    const user = storedUser ? JSON.parse(storedUser) : null;

    console.log("protected route user-->", user);

    if (!user || !user.googleId) {
        return <Navigate replace to="/signup" />;
    }

    return children;
};





// App or component that contains the routing


