import React, { useEffect, useState } from "react";
import axios from "axios";
import { set } from "mongoose";
import { fetchChatHistory } from "../../utils/chatHistory";

const ChatHistory = ({ chatData, setChatData }) => {
  useEffect(() => {
    fetchChatHistory(chatData, setChatData);
    console.log("chatData-->", chatData);
  }, []);

  return (
    <div>
      <h1>Chat History</h1>
      <ul>
        {chatData.map((chat, index) => (
          <li key={index}>{chat}</li>
        ))}
      </ul>
    </div>
  );
};

export default ChatHistory;
