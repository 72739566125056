import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/mithilastack.svg";
import backgroundImage from "../../assets/ai5.gif"; // Add your background image

const FooterSection = styled.div`
  background-color: white;
  border-bottom: 4px solid #e0e0e0;
  color: black;
  background-size: cover;
`;

const FooterContainer = styled.div`
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;
  align-items: flex-start;
  color: black;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;


const LogoAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const FooterSign = styled.div`
  color: black;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  font-weight: bold;
  line-height: 1.2;
`;

const FtDescription = styled.p`
  width: 100%;
  margin: 16px 0 40px;
  color: black;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 20px;
  letter-spacing: .8px;
  line-height: 1.7rem;

  @media screen {max-width: 420px;
    width: 100%;
    
  }
`;

const FtList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .ft-list-title {
    margin: 16px 0;
    color: black;
    /* background-color: skyblue; */
   
    padding: 8px 12px;
    
    /* border: 2px solid #5b87f7; */
    margin-top: 2px;
    
       font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .8px;
      @media (max-width: 768px) {
   margin-left: 35px;
  }
  }

  & .ft-list-items {
    list-style-type: none;
    padding-left: 0;
  }

  & .ft-list-items li {
    margin: 12px 0; /* Adjust margin as needed */
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 16px;
   
    letter-spacing: .8px;
  }

  & .ft-list-items li a {
    text-decoration: none;
    color: black;
    padding: 0;
    border-bottom: 2px dotted transparent;
    transition: border .2s ease;
  }

  & .ft-list-items li a:hover {
    border-bottom: 2px  #a5a7ac;
  }
 
`;

const FtCopyright = styled.div`
  padding: 4px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 17px;
  font-weight: bold;
  letter-spacing: .4px;

  & button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: inherit;
    font-size: inherit;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FtSocialLinks = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  & li a {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid black;
    border-radius: 50%;
    cursor: pointer;
  }

  & li a svg {
    width: 24px;
    height: 24px;
    padding: 1px;
  }

  & li a svg path {
    fill: skyblue;
  }

  & li a:hover,
  & li a:hover svg path {
    fill: skyblue;
    border: 2px solid black;
  }
`;

const Footer = () => {
  return (
    <FooterSection id="footer">
      <FooterContainer>
        <div className="footer-info">
          <div className="ft-info-p1">
            <LogoAndText>
              <Link to="/">
                <img
                  src={logo}
                  alt="Logo"
                  className="navbar-logo"
                  style={{ width: "80px", height: "70px" }}
                />
              </Link>
              <FooterSign>
                <span>MithilaStackAI</span>
                <br />
                <span></span>
              </FooterSign>
            </LogoAndText>
            <FtDescription>
              Unleash the power of AI to transform your business. We provide expert AI solutions to help you grow your business.
            </FtDescription>
            <FooterSign>
              <span>Contact Us :</span>
            </FooterSign>
            <FtDescription>
              mithilastack@gmail.com
              <br />
              1234567890
              <br />
Address :-
              Azamnagar,
            
              Darbhanga
846004  </FtDescription>

          </div>
        </div>

        <FtList>
          <p className="ft-list-title">About Us</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
             <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
             <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
            <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
            <li>
              <a href="#services">Ultimate Translator</a>
            </li>
          </ul>
        </FtList>
          <FtList>
          <p className="ft-list-title">Services</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
           
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
            <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
            <li>
              <a href="#services">Ultimate Translator</a>
            </li>
          </ul>
        </FtList>

        <FtList id="contact">
          <p className="ft-list-title">Features</p>
          <ul className="ft-list-items">
            <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
           
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
           
            <li>
              <a href="#services">Your ultimate copywriter</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
             <li>
              <a href="#services">Ultimate Marketer</a>
            </li>
           
          </ul>
        </FtList>
      </FooterContainer>

      <FtCopyright>
        <button
          onClick={() => window.open("http://www.mithilastack.com", "_blank")}
          rel="noopener noreferrer"
        >
          © 2024 Proudly Made by Mithilastack.
        </button>

        <FtSocialLinks>
          <li>
            <a
              href="https://www.linkedin.com/company/mithila-stack/"
              title="LinkedIn"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://facebook.com/"
              title="FaceBook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 320 512"
              >
                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="https://x.com/MithilaStack"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
          </li>
        </FtSocialLinks>
      </FtCopyright>
    </FooterSection>
  );
};

export default Footer;
