import React, { useContext, useEffect } from "react";
import style from "./style.css";
import { handleSignIn, fetchUserProfile } from "../../components/auth/auth";
import { UserContext } from "../../context/UserContext";

const Signup = () => {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    fetchUserProfile(setUser);
  }, []);

  return (
    <div className="signup">
      <h2>Signup</h2>
      <button className="google-btn" onClick={handleSignIn}>
        Continue with Google
      </button>
    </div>
  );
};

export default Signup;
