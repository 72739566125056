import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
// import Navbar from "../src/components/Navbar";
import Features from "./components/Features";
import Functions from "./components/Functions";
import Audience from "./components/Audience";
import Footer from "./components/Footer";
import AudioRecorder from "./components/audioRecorded/AudioRecorder";
import Navbar from "./components/Navbar";
import ChatAI from "./components/aiPage/ChatAI";
import Signup from "./Pages/signup/Signup";
import Profile from "./components/Profile";
import { ProtectedRoute } from "./utils/isUserAuthenticated";
export default function Home() {
  console.log("home");
  return (
    <Router>
      <main>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Features />
                <Functions />
                <Audience />
                <Footer />
              </>
            }
          />
          <Route
            path="/chatai"
            element={
              <ProtectedRoute>
                <ChatAI />
              </ProtectedRoute>
            }
          />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </main>
    </Router>
  );
}
