import React, { useState, useEffect, useRef, useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import { LuMic } from "react-icons/lu";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import HeroBottomSVG from "./HeroBottomSVG";
import { StargateColors } from "../../utils/Colors";
import BackgroundGif from "../../assets/ai2.gif"; // Adjust the path as needed
import AudioRecorder from "../audioRecorded/AudioRecorder";
import Signup from "../../Pages/signup/Signup";
import { UserContext } from "../../context/UserContext";

// Styled components
const NavbarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  @media (max-width: 640px) {
    padding: 1rem;
  }
`;

const FlexContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align items to the left */
  min-height: 100vh;
  background: url(${BackgroundGif}) no-repeat center center;
  background-size: cover;
  position: relative;
  padding: 2rem;

  @media (max-width: 640px) {
    padding: 1rem;
  }
`;

const HighlightText = styled.div`
  background-color: #ffffff50;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  margin-bottom: 1rem;
  margin-top: 6rem; /* Adjust top margin to fit the navbar */
  font-size: 0.875rem;
  color: ${StargateColors.white};
`;

const MainHeading = styled.h1`
  font-size: 1rem;
  color: ${StargateColors.white};
  line-height: 0;
  text-align: left;

  @media (min-width: 768px) {
    font-size: 4rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const SubText = styled.p`
  color: ${StargateColors.white};
  max-width: 300px;
  text-align: left;
  margin-top: 1rem;
`;

const LinkText = styled(RouterLink)`
  margin-top: 0.5rem;
  color: ${StargateColors.white};
  opacity: 0.75;
  transition: all 0.25s ease;

  &:hover {
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const PulseCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite;
  z-index: -1;
`;

const Button = styled.button`
  background-color: #fff;
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  color: var(--white);
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 1rem; /* Add gap between buttons */

  &:hover {
    transform: scale(1.1);
    background-color: #f0f0f0;
  }

  &:focus {
    transform: scale(0.9);
  }

  svg {
    fill: var(--white);
  }

  @media only screen and (max-width: 40em) {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const Header = () => {
  const [currentWord, setCurrentWord] = useState();
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  console.log("user-->", user);

  // const words = [
  //   "Copywriter",
  //   "Assistant",
  //   "Marketer",
  //   "Developer",
  //   "Strategist",
  //   "Creator",
  // ];
  const words = "Copywriter";

  useEffect(() => {
    setCurrentWord(words);
  }, []);

  //  useEffect(() => {
  //   setCurrentWord(words[index]);
  //   const interval = setInterval(() => {
  //     setIndex((prevIndex) => {
  //       const newIndex = (prevIndex + 1) % words.length;
  //       setCurrentWord(words[newIndex]);
  //       return newIndex;
  //     });
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, []);

  const handleButtonClick = () => {
    console.log("user header-->", user);
    if (user.name && user.email && user.picture) {
      navigate("/chatai"); // Use navigate for navigation
    } else {
      navigate("/signup"); // Navigate to Signup page if user is not defined
    }
  };

  return (
    <FlexContainer initial="initial" animate="animate">
      <NavbarContainer>
        <Navbar />
      </NavbarContainer>

      <MainHeading>Focus on what matters</MainHeading>

      <MainHeading>Instead of fighting with ai</MainHeading>

      <SubText>
        Elevating AI: The Ultimate Platform for Intelligent Solutions
      </SubText>

      <Button onClick={handleButtonClick}>
        <LuMic />
        {"Start Recording"}
      </Button>

      <LinkText to="/features">Discover MithilastackAi</LinkText>
    </FlexContainer>
  );
};

// const Header = () => {
//   const indexRef = useRef(0);
//   const words = [
//     "Copywriter",
//     "Assistant",
//     "Marketer",
//     "Developer",
//     "Strategist",
//     "Creator",
//   ];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       indexRef.current = (indexRef.current + 1) % words.length;
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   console.log("hii from header");

//   return (
//     <FlexContainer
//       initial="initial"
//       animate="animate"
//     >
//       <MainHeading>Your Ultimate</MainHeading>
//       <AnimatePresence mode="wait">
//         <MainHeading
//           as={motion.h1}
//           key={words[indexRef.current]}
//           initial={{ opacity: 0.2, filter: "blur(4px)" }}
//           animate={{ opacity: 1, filter: "blur(0px)" }}
//           exit={{ opacity: 0.2, filter: "blur(4px)" }}
//         >
//           {words[indexRef.current]}
//         </MainHeading>
//       </AnimatePresence>
//       <SubText>
//         Elevating AI: The Ultimate Platform for Intelligent Solutions
//       </SubText>
//       <Button href="#" whileHover={{ scale: 1.1 }}>
//         <LuMic />
//         Record Your Voice Now!
//       </Button>
//       <LinkText to="/features">Discover MithilastackAi</LinkText>
//     </FlexContainer>
//   );
// };

export default Header;

// import React, { useState, useEffect } from "react";
// import { AnimatePresence, motion } from "framer-motion";
// import styled from "styled-components";
// import { LuMic } from "react-icons/lu";
// import { Link as RouterLink } from "react-router-dom";
// import Navbar from "../Navbar";
// import HeroBottomSVG from "./HeroBottomSVG";
// import { StargateColors } from "../../utils/Colors";
// import BackgroundGif from "../../assets/ai2.gif"; // Adjust the path as needed

// // Styled components
// const NavbarContainer = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   z-index: 10;

//   @media (max-width: 640px) {
//     padding: 1rem;
//   }
// `;

// const FlexContainer = styled(motion.div)`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start; /* Align items to the left */
//   min-height: 100vh;
//   background: url(${BackgroundGif}) no-repeat center center;
//   background-size: cover;
//   position: relative;
//   padding: 2rem;

//   @media (max-width: 640px) {
//     padding: 1rem;
//   }
// `;

// const HighlightText = styled.div`
//   background-color: #ffffff50;
//   padding: 0.5rem 1rem;
//   border-radius: 9999px;
//   margin-bottom: 1rem;
//   margin-top: 6rem; /* Adjust top margin to fit the navbar */
//   font-size: 0.875rem;
//   color: ${StargateColors.white};
// `;

// const MainHeading = styled.h1`
//   font-size: 2rem;
//   color: ${StargateColors.white};
//   line-height: 0;
//   text-align: left;

//   @media (min-width: 768px) {
//     font-size: 4rem;
//   }

//   @media (min-width: 1024px) {
//     font-size: 4rem;
//   }
// `;

// const SubText = styled.p`
//   color: ${StargateColors.white};
//   max-width: 300px;
//   text-align: left;
//   margin-top: 1rem;
// `;

// const Button = styled(motion.a)`
//   display: inline-flex;
//   align-items: center;
//   gap: 0.5rem;
//   margin-top: 1rem;
//   padding: 1rem 2rem;
//   font-size: 1rem;
//   border-radius: 20px;
//   background-color: grey;
//   color: white;
//   cursor: pointer;
//   text-decoration: none;

//   &:hover {
//     scale: 1.1;
//   }
// `;

// const LinkText = styled(RouterLink)`
//   margin-top: 0.5rem;
//   color: ${StargateColors.white};
//   opacity: 0.75;
//   transition: all 0.25s ease;

//   &:hover {
//     opacity: 1;
//   }
// `;

// const Header = () => {
//   const [currentWord, setCurrentWord] = useState<string>("");
//   const [index, setIndex] = useState(0);

//   const words = [
//     "Copywriter",
//     "Assistant",
//     "Marketer",
//     "Developer",
//     "Strategist",
//     "Creator",
//   ];

//   useEffect(() => {
//     setCurrentWord(words[index]); // Set initial word

//     const interval = setInterval(() => {
//       setIndex((prevIndex) => (prevIndex + 1) % words.length);
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   useEffect(() => {
//     setCurrentWord(words[index]);
//   }, [index]);

//   console.log("hii from header");

//   return (
//     <FlexContainer initial="initial" animate="animate">
//       <MainHeading>Your Ultimate</MainHeading>
//       <AnimatePresence mode="wait">
//         <MainHeading
//           as={motion.h1}
//           key={currentWord}
//           initial={{ opacity: 0.2, filter: "blur(4px)" }}
//           animate={{ opacity: 1, filter: "blur(0px)" }}
//           exit={{ opacity: 0.2, filter: "blur(4px)" }}
//         >
//           {currentWord}
//         </MainHeading>
//       </AnimatePresence>
//       <SubText>
//         Elevating AI: The Ultimate Platform for Intelligent Solutions
//       </SubText>
//       <Button href="#" whileHover={{ scale: 1.1 }}>
//         <LuMic />
//         Record Your Voice Now!
//       </Button>
//       <LinkText to="/features">Discover MithilastackAi</LinkText>
//     </FlexContainer>
//   );
// };

// export default Header;
