// import React, { useState, createContext, useEffect } from "react";

// export const UserContext = createContext(null);

// const UserContextProvider = (props) => {
//   console.log("Rendering UserContextProvider");
//   const [user, setUser] = useState({
//     googleId: "",
//     name: "",
//     email: "",
//     picture: "",
//   });
//   console.log("user-->", user);

//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {props.children}
//     </UserContext.Provider>
//   );
// };

// export default UserContextProvider;

// UserContextProvider.js

import React, { useState, createContext, useEffect } from "react";

export const UserContext = createContext(null);

const UserContextProvider = (props) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser
      ? JSON.parse(storedUser)
      : {
          googleId: "",
          name: "",
          email: "",
          picture: "",
        };
  });

  useEffect(() => {
    // Update localStorage when user state changes
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
