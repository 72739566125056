// import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react";
// import React, { ReactNode } from "react";

// const Audience = () => {
//   return (
//     <Flex
//       id="audience"
//       direction={"column"}
//       justify={"center"}
//       align={"center"}
//       my={24}
//       px={2}
//       maxW={1200}
//       mx={"auto"}
//     >
//       <Heading
//         fontSize={{
//           base: 32,
//           md: 48,
//         }}
//         textAlign={"center"}
//         mb={10}
//       >
//         We recommend Stargate for
//       </Heading>
//       <Grid
//         templateColumns={{
//           base: "repeat(2, 1fr)",
//           md: "repeat(3, 1fr)",
//         }}
//         gap={4}
//         w={{
//           base: "auto",
//           xl: "full",
//         }}
//       >
//         {Cards.map((card) => (
//           <Card key={card.title} color={card.color}>
//             {card.title}
//           </Card>
//         ))}
//       </Grid>
//       <Heading
//         fontSize={{
//           base: 16,
//           md: 24,
//           lg: 32,
//         }}
//         textAlign={"center"}
//         mt={10}
//       >
//         and many others.
//       </Heading>
//     </Flex>
//   );
// };

// interface CardProps {
//   color: string;
//   children: ReactNode;
// }

// const Card = ({ color, children }: CardProps) => {
//   return (
//     <Flex
//       align={"center"}
//       bg={`${color}25`}
//       px={8}
//       py={5}
//       rounded={"lg"}
//       transition={"all .25s ease"}
//       _hover={{
//         boxShadow: `0px 5px 50px 2px ${color}25`,
//         transform: "translateY(-5px)",
//       }}
//     >
//       <Box
//         mr={4}
//         w={2}
//         h={2}
//         bg={color}
//         rounded={"full"}
//         outline={"5px solid white"}
//       />
//       <Text
//         color={color}
//         fontSize={{
//           base: 12,
//           md: 14,
//           lg: 16,
//         }}
//         fontWeight={600}
//       >
//         {children}
//       </Text>
//     </Flex>
//   );
// };

// const Cards = [
//   {
//     title: "Digital Agencies",
//     color: "#E6990B",
//   },
//   {
//     title: "Designers",
//     color: "#A431C7",
//   },
//   {
//     title: "Entrepreneurs",
//     color: "#42C9B2",
//   },
//   {
//     title: "Developers",
//     color: "#348FDA",
//   },
//   {
//     title: "Students",
//     color: "#41B668",
//   },
//   {
//     title: "Startups",
//     color: "#DA4D8F",
//   },
// ];

// export default Audience;


// import React from "react";

import styled from "styled-components";
import React, { ReactNode } from 'react';


const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;
  padding: 0 0.5rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const Heading = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2.5rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1280px) {
    width: 100%;
  }
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => `${color}25`};
  padding: 1.25rem 2rem;
  border-radius: 0.5rem;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0px 5px 50px 2px ${({ color }) => `${color}25`};
    transform: translateY(-5px);
  }
`;

const Dot = styled.div`
  margin-right: 1rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  outline: 5px solid white;
`;

const Text = styled.p`
  color: ${({ color }) => color};
  font-size: 0.75rem;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 0.875rem;
  }

  @media (min-width: 1024px) {
    font-size: 1rem;
  }
`;
interface CardProps {
  color: string;
  children: ReactNode;
}

const Audience = () => {
  return (
    <FlexContainer id="audience">
      <Heading>We recommend MithilastackAI for</Heading>
      <GridContainer>
        {Cards.map((card) => (
          <Card key={card.title} color={card.color}>
            {card.title}
          </Card>
        ))}
      </GridContainer>
      <Heading style={{ fontSize: "1rem", marginTop: "2.5rem" }}>
        and many others.
      </Heading>
    </FlexContainer>
  );
};

const Card = ({ color, children }: CardProps) => {
  return (
    <CardContainer color={color}>
      <Dot color={color} />
      <Text color={color}>{children}</Text>
    </CardContainer>
  );
};

const Cards = [
  {
    title: "Digital Agencies",
    color: "#E6990B",
  },
  {
    title: "Designers",
    color: "#A431C7",
  },
  {
    title: "Entrepreneurs",
    color: "#42C9B2",
  },
  {
    title: "Developers",
    color: "#348FDA",
  },
  {
    title: "Students",
    color: "#41B668",
  },
  {
    title: "Startups",
    color: "#DA4D8F",
  },
];

export default Audience;
