import axios from "axios";
import BASE_URL from "../constant/url";
export const fetchChatHistory = async (chatData, setChatData) => {
    try {
        console.log("hiii from fecch chat history");
        const response = await axios.get(`${BASE_URL}/chat/all`, {
            withCredentials: true, // This includes credentials like cookies in the request
        });
        console.log("Chat history fetched:", response.data);

        // Collect all chat data
        const allChats = response.data[0].chats.map(
            (chat) => chat.transcribedText[0].data
        );
        console.log("All chats:", allChats);

        // Update the state once with all chat data
        setChatData(allChats);
        console.log("chatData-->", chatData);
    } catch (error) {
        console.error("Failed to fetch chat history:", error);
    }
};