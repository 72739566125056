// auth.js
import axios from 'axios';

import BASE_URL from '../../constant/url';

import { calcLength } from 'framer-motion';
;



console.log("API_BASE_URL", BASE_URL);

// https://backend.mithilastack.com/ai-react//auth/google

export const handleSignIn = () => {
    window.location.href = `${BASE_URL}/auth/google`;
};

export const fetchUserProfile = async (setUser) => {
    try {
        const response = await axios.get(`${BASE_URL}/auth/profile`, {
            withCredentials: true,
        });
        console.log(response.data);
        if (response.data) {
            setUser({
                googleId: response.data.googleId,
                name: response.data.name,
                email: response.data.email,
                picture: response.data.picture,
            });
        }

        localStorage.setItem('user', JSON.stringify(response.data));

    } catch (error) {
        console.log(error);
    }
};