// import React, { useState } from "react";
// import styled from "styled-components";
// import { StargateColors } from "../../utils/Colors";
// import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";

// export interface SidebarProps {
//   isOpen: boolean;
// }

// const Sidebar =
//   styled.div <
//   SidebarProps >
//   `
//   width: 250px;
//   position: fixed;
//   right: 0;
//   top: 0;
//   height: 100%;
//   background-color: #fff;
//   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
//   padding: 2rem;
//   transform: ${(props) =>
//     props.isOpen ? "translateX(0)" : "translateX(100%)"};
//   transition: transform 0.3s ease-in-out;
//   z-index: 1000;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (max-width: 768px) {
//     width: 200px;
//   }

//   @media (max-width: 480px) {
//     width: 150px;
//   }
// `;

// const CloseButton = styled.div`
//   position: absolute;
//   top: 1rem;
//   left: 1rem;
//   font-size: 1.5rem;
//   cursor: pointer;
// `;

// const ProfileImage = styled.div`
//   width: 100px;
//   height: 100px;
//   background-color: ${StargateColors.primary};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   font-size: 2rem;
//   margin-bottom: 1rem;

//   @media (max-width: 768px) {
//     width: 80px;
//     height: 80px;
//     font-size: 1.5rem;
//   }

//   @media (max-width: 480px) {
//     width: 60px;
//     height: 60px;
//     font-size: 1.2rem;
//   }
// `;

// const Border = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: ${StargateColors.primary};
//   margin: 1rem 0;
// `;

// const SidebarItem = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0.5rem 0;
//   font-size: 1.2rem;
//   cursor: pointer;
//   text-align: center;
//   width: 100%;

//   &:hover {
//     color: ${StargateColors.primary};
//   }

//   svg {
//     margin-right: 0.5rem;
//   }

//   @media (max-width: 768px) {
//     font-size: 1rem;
//   }

//   @media (max-width: 480px) {
//     font-size: 0.8rem;
//   }
// `;

// const ProfileIcon = styled.div`
//   position: fixed;
//   top: 1rem;
//   right: 1rem;
//   width: 50px;
//   height: 50px;
//   background-color: ${StargateColors.primary};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   cursor: pointer;
//   z-index: 1001;
//   font-size: 1.5rem;

//   @media (max-width: 768px) {
//     width: 40px;
//     height: 40px;
//     font-size: 1.2rem;
//   }

//   @media (max-width: 480px) {
//     width: 30px;
//     height: 30px;
//     font-size: 1rem;
//   }
// `;

// const ProfileSidebar = (user) => {
//   console.log("profile sidebar user", user);
//   const [isSidebarOpen, setSidebarOpen] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   return (
//     <>
//       <ProfileIcon onClick={toggleSidebar}>P</ProfileIcon>
//       <Sidebar isOpen={isSidebarOpen}>
//         <CloseButton onClick={toggleSidebar}>×</CloseButton>
//         <ProfileImage>S</ProfileImage>
//         <Border />
//         <SidebarItem>
//           <FaUserCircle />
//           Your Profile
//         </SidebarItem>
//         <Border />
//         <SidebarItem>
//           <FaSignOutAlt />
//           Log Out
//         </SidebarItem>
//       </Sidebar>
//     </>
//   );
// };

// export default ProfileSidebar;

// import React, { useContext, useState } from "react";
// import styled from "styled-components";
// import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
// import { UserContext } from "../../context/UserContext";
// import { Navigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// const StargateColors = {
//   primary: "#someColor", // Define your primary color here
// };

// const Sidebar = styled.div`
//   width: 250px;
//   position: fixed;
//   right: 0;
//   top: 0;
//   height: 100%;
//   background-color: #fff;
//   box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
//   padding: 2rem;
//   transform: ${(props) =>
//     props.isOpen ? "translateX(0)" : "translateX(100%)"};
//   transition: transform 0.3s ease-in-out;
//   z-index: 1000;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   @media (max-width: 768px) {
//     width: 200px;
//   }

//   @media (max-width: 480px) {
//     width: 150px;
//   }
// `;

// const CloseButton = styled.div`
//   position: absolute;
//   top: 1rem;
//   left: 1rem;
//   color: black;
//   font-size: 1.5rem;
//   cursor: pointer;
// `;

// const ProfileImage = styled.div`
//   width: 100px;
//   height: 100px;
//   background-color: ${StargateColors.primary};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   font-size: 2rem;
//   margin-bottom: 1rem;

//   @media (max-width: 768px) {
//     width: 80px;
//     height: 80px;
//     font-size: 1.5rem;
//   }

//   @media (max-width: 480px) {
//     width: 60px;
//     height: 60px;
//     font-size: 1.2rem;
//   }
// `;

// const Border = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: ${StargateColors.primary};
//   margin: 1rem 0;
// `;

// const SidebarItem = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0.5rem 0;
//   font-size: 1.2rem;
//   color: black;
//   cursor: pointer;
//   text-align: center;
//   width: 100%;

//   &:hover {
//     color: ${StargateColors.primary};
//   }

//   svg {
//     margin-right: 0.5rem;
//   }

//   @media (max-width: 768px) {
//     font-size: 1rem;
//   }

//   @media (max-width: 480px) {
//     font-size: 0.8rem;
//   }
// `;

// const ProfileIcon = styled.div`
//   position: fixed;
//   top: 1rem;
//   right: 1rem;
//   width: 50px;
//   height: 50px;
//   background-color: ${StargateColors.primary};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   cursor: pointer;
//   z-index: 1001;
//   font-size: 1.5rem;

//   @media (max-width: 768px) {
//     width: 40px;
//     height: 40px;
//     font-size: 1.2rem;
//   }

//   @media (max-width: 480px) {
//     width: 30px;
//     height: 30px;
//     font-size: 1rem;
//   }
// `;

// const ProfileSidebar = () => {
//   const [isSidebarOpen, setSidebarOpen] = useState(false);
//   const { user, setUser } = useContext(UserContext);
//   const navigate = useNavigate();
//   // console.log("user-->", user);
//   // console.log("profile sidebar user", user);
//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   const handleLogout = async () => {
//     try {
//       // Call the server-side logout endpoint using Axios
//       await axios.get("http://localhost:4000/auth/logout");

//       // Clear any client-side stored user information or tokens
//       // localStorage.removeItem("token"); // Adjust based on your token storage key

//       // Use setUser from your UserContext to clear the user state
//       setUser({
//         name: "",
//         email: "",
//         picture: "",
//       });
//       console.log("user Profile-->", user);
//       // Redirect the user to the login page or update the UI accordingly
//       // This step depends on your routing setup, e.g., using React Router
//       navigate("/"); // Uncomment and use if you're using React Router or a similar library
//     } catch (error) {
//       console.error("Logout failed", error);
//       // Handle any errors, such as showing an error message to the user
//     }
//   };

//   return (
//     <>
//       <ProfileIcon onClick={toggleSidebar}>
//         {user?.name
//           .match(/\b(\w)/g)
//           ?.slice(0, 2)
//           .join("")}
//       </ProfileIcon>
//       <Sidebar isOpen={isSidebarOpen}>
//         <CloseButton onClick={toggleSidebar}>×</CloseButton>
//         <ProfileImage>
//           <img
//             src={user?.picture}
//             alt="profile"
//             style={{
//               borderRadius: "50%",
//               objectFit: "cover",
//               width: "100px", // Adjust as needed
//               height: "100px", // Ensure this is the same as width for a perfect circle
//             }}
//           />
//         </ProfileImage>
//         <Border />
//         <SidebarItem>
//           <FaUserCircle />
//           Your Profile
//         </SidebarItem>
//         <Border />
//         <SidebarItem onClick={handleLogout}>
//           <FaSignOutAlt style={{ color: "black", fontSize: "24px" }} />
//           Log Out
//         </SidebarItem>
//       </Sidebar>
//     </>
//   );
// };

// export default ProfileSidebar;

// import React, { useContext, useState } from "react";
// import styled from "styled-components";
// import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
// import { UserContext } from "../../context/UserContext";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const SidebarContainer = styled.div`
//   position: relative;
//   display: inline-block;
// `;

// const ProfileIcon = styled.div`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   img {
//     border-radius: 50%;
//     width: 40px;
//     height: 40px;
//   }
// `;

// const DropdownMenu = styled.div`
//   display: ${({ isOpen }) => (isOpen ? "block" : "none")};
//   position: absolute;
//   top: 100%;
//   right: 0;
//   width: 200px;
//   height: 500px;
//   background-color: black;
//   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
//   z-index: 1;
// `;

// const DropdownItem = styled.a`
//   display: block;
//   padding: 12px 16px;
//   text-decoration: none;
//   color: black;
//   &:hover {
//     background-color: #ddd;
//   }
// `;

// const ProfileSidebar = () => {
//   const { user, setUser } = useContext(UserContext);
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   const toggleDropdown = () => {
//     setIsOpen(!isOpen);
//   };

//   const handleSignOut = async () => {
//     try {
//       // Call the server-side logout endpoint using Axios
//       await axios.get("http://localhost:4000/auth/logout");

//       // Clear the user state
//       setUser({
//         name: null,
//         email: null,
//         picture: null,
//       });

//       // Redirect the user to the home page or update the UI accordingly
//       navigate("/");
//     } catch (error) {
//       console.error("Logout failed", error);
//       // Handle any errors, such as showing an error message to the user
//     } finally {
//       setIsOpen(false);
//     }
//   };

//   return (
//     <SidebarContainer>
//       <ProfileIcon onClick={toggleDropdown}>
//         <img src={user.picture} alt="Profile" />
//       </ProfileIcon>
//       <DropdownMenu isOpen={isOpen}>
//         <DropdownItem href="#">
//           <FaUserCircle /> {user.name}
//         </DropdownItem>
//         <DropdownItem href="#" onClick={handleSignOut}>
//           <FaSignOutAlt /> Sign Out
//         </DropdownItem>
//       </DropdownMenu>
//     </SidebarContainer>
//   );
// };

// export default ProfileSidebar;

import React, { useContext, useState } from "react";
import styled from "styled-components";
import { FaUserCircle, FaSignOutAlt, FaTimes } from "react-icons/fa";
import { UserContext } from "../../context/UserContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../constant/url";

const SidebarContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`;

const DropdownMenu = styled.div`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  position: absolute;
  top: -40px;
  right: -138%;
  width: 300px;
  height: 200vh;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* border-radius: 8px; */
  overflow: hidden;
  transform: ${({ isOpen }) => (isOpen ? "scaleY(1)" : "scaleY(0)")};
  transform-origin: top;
  transition: transform 0.3s ease-in-out;

  @media (min-width: 1400px) {
    right: -638%;
  }
`;

const CloseButton = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  padding: 8px 16px;
  cursor: pointer;
  svg {
    color: #999;
    &:hover {
      color: #666;
    }
  }
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  text-decoration: none;
  color: black;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }

  svg {
    margin-right: 8px;
  }
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;
  margin: 16px auto;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 1rem 0;
`;

const ProfileSidebar = () => {
  const { user, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleSignOut = async () => {
    try {
      // Call the server-side logout endpoint using Axios
      await axios.post(
        `${BASE_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );

      // Clear the user state
      setUser({
        name: null,
        email: null,
        picture: null,
      });
      localStorage.removeItem("user");
      // Redirect the user to the home page or update the UI accordingly
      navigate("/");
    } catch (error) {
      console.error("Logout failed", error);
      // Handle any errors, such as showing an error message to the user
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <SidebarContainer>
      <ProfileIcon onClick={toggleDropdown}>
        <img src={user.picture} alt="Profile" />
      </ProfileIcon>
      <DropdownMenu isOpen={isOpen}>
        <CloseButton onClick={closeDropdown}>
          <FaTimes />
        </CloseButton>
        <ProfileImage>
          <img src={user?.picture} alt="Profile" />
        </ProfileImage>

        <DropdownItem>
          <FaUserCircle /> {user.name}
        </DropdownItem>
        <Border />
        <DropdownItem onClick={handleSignOut}>
          <FaSignOutAlt /> Sign Out
        </DropdownItem>
      </DropdownMenu>
    </SidebarContainer>
  );
};

export default ProfileSidebar;
