export const StargateColors = {
  primary: "#e340bf",
  secondary: "#a440e3",
  grey: "#656f7e",
  black: "#09090b",
  darkBg: "#262626",
  white: "#FFFFFF",
  lightBg: "#d9d9d9",
  lightGrey: "#f2f2f2",
};

export const MithilaStackColors = {
  primary: "#E6E6FA",
  secondary: "#a440e3",
  grey: "#656f7e",
  black: "#09090b",
  darkBg: "#262626",
  white: "#FFFFFF",
  lightBg: "#d9d9d9",
  lightGrey: "#f2f2f2",
};
