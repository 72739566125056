// import React, { useState, useEffect, useRef } from "react";
// import { LuMic } from "react-icons/lu";
// import styled, { keyframes } from "styled-components";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import logoo from "../../assets/mithilastack.svg";
// import Navbar from "../Navbar";
// import Footer from "../Footer"; // Make sure to import the Footer component
// import axios from "axios";
// import { useNavigate } from "react-router-dom";

// const pulse = keyframes`
//   0% {
//     transform: scale(1);
//     opacity: 0.7;
//   }
//   50% {
//     transform: scale(1.5);
//     opacity: 0;
//   }
//   100% {
//     transform: scale(1);
//     opacity: 0;
//   }
// `;

// const Container = styled.div`
// max-width: 1200px;
// width: 100%;
//   display: flex;
//   flex-direction: column;
//   height: 100vh;

//   @media (max-width: 768px) {
//     height: auto;

//   }
// `;

// const Content = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex: 1;
// `;

// const Sidebar = styled.div`
//   width: 20%;
//   background-color: #f7f7f7;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   padding: 20px;
//   box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
//   @media (max-width: 768px) {
//     width: 30%;
//   }
//   @media (max-width: 600px) {
//     display: none;
//   }
// `;

// const Logo = styled.img`
//   width: 30%;
//   border-radius: 30px;
//   /* margin-bottom: 20px; */
// `;

// const Quote = styled.div`
//   margin-top: 20px;
//   font-size: 16px;
//   color: #555;
//   text-align: center;
// `;

// const MainContent = styled.div`
//   width: 80%;
//   padding: 20px;
//   display: flex;
//   flex-direction: column;
//   @media (max-width: 768px) {
//     width: 70%;
//   }
//   @media (max-width: 600px) {
//     width: 100%;
//   }
// `;

// const Header = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;

// const LoadingText = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 80vh;
//   font-size: 24px;
//   font-weight: bold;
//   text-align: center;
// `;

// const StyledSlider = styled(Slider)`
//   .slick-slide img {
//     width: 60%;
//     height: auto;
//     object-fit: cover;
//     border-radius: 10px;
//     margin: 40px auto;
//     padding: 10px;
//     height: 400px;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     transition: transform 0.2s;
//     animation: shimmer 1s infinite linear forwards;
//     background: #f6f7f8;
//     background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
//     background-size: 600px 104px;
//   }
//   .slick-slide img:hover {
//     transform: scale(1.05);
//   }
// `;

// const Transcript = styled.div`
//   width: 50%;
//   height: 300px;
//   text-align: center;
//   margin: 0 auto;
// `;

// const ChatBody = styled.div`
//   display: flex;
//   justify-content: space-between;
//   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//   border-radius: 10px;
//   overflow: hidden;
//   padding: 5px;
//   width: 72%;
//   margin: 20px auto;
//   @media (max-width: 600px) {
//     flex-direction: column;
//     width: 90%;
//   }
// `;

// const ChatBodyLeft = styled.div`
//   flex: 3;
//   padding: 10px;
// `;

// const ChatBodyRight = styled.div`
//   flex: 1;
//   display: flex;
//   justify-content: flex-end;
//   padding: 10px;
//   @media (max-width: 600px) {
//     justify-content: center;
//     margin-top: 20px;
//   }
// `;

// const Button = styled.button`
//   background-color: #fff;
//   padding: 0.7rem 1.5rem;
//   border-radius: 30px;
//   color: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
//   font-weight: 600;
//   font-size: 1.2rem;
//   cursor: pointer;
//   /* transition: all 0.3s ease-in-out; */
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
//   border: ${(props) => (props.isRecording ? "none" : "2px solid var(--black)")};
//   box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//   position: relative;
//   margin-bottom: 1rem;
//   z-index: 1;

//   &:hover {
//     transform: scale(1.1);
//     background-color: ${(props) => (props.isRecording ? "#FF6347" : "#f0f0f0")};
//   }

//   &:focus {
//     transform: scale(0.9);
//   }

//   svg {
//     fill: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
//   }

//   @media only screen and (max-width: 40em) {
//     font-size: 1rem;
//     padding: 0.5rem 1rem;
//     &:hover {
//       transform: none;
//     }
//     &:focus {
//       transform: none;
//     }
//   }
// `;

// const PulseCircle = styled.div`
//   position: absolute;
//   width: 120px;
//   height: 120px;
//   background-color: rgba(255, 0, 0, 0.5);
//   border-radius: 50%;
//   animation: ${pulse} 1.5s infinite;
//   z-index: -1;
// `;

// const FileInputLabel = styled.label`
//   cursor: pointer;
//   margin-left: 15px;
// `;

// const FileInput = styled.input`
//   opacity: 0;
//   width: 0.1px;
//   height: 0.1px;
//   position: absolute;
//   z-index: -1;
// `;
// const ChatTitle = styled.div`
//   font-size: 11px; // Adjust the font size as needed
//   font-weight: bold;
//   /* text-decoration: underline; */
// `;
// const HeaderContainer = styled.div`
//   display: flex;
//   align-items: center; // Aligns items vertically in the center
//   justify-content: space-between; // Puts maximum space between the logo and the text
//   margin-bottom: 20px; // Adds some space below the header
// `;
// const ChatAI = () => {
//   const [photos, setPhotos] = useState([]);
//   const [subtitles, setSubtitles] = useState(null);
//   const [isRecording, setIsRecording] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [audioBlob, setAudioBlob] = useState(null);
//   const navigate = useNavigate();
//   const mediaRecorder = useRef(null);
//   const audioChunks = useRef([]);

//   useEffect(() => {
//     async function getPhotos() {
//       try {
//         const response = await axios.get("https://picsum.photos/v2/list");
//         setPhotos(response.data);
//       } catch (error) {
//         console.error(error);
//       }
//     }
//     getPhotos();
//   }, []);

//   var settings = {
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 2000,
//   };

//   useEffect(() => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.ondataavailable = (event) => {
//         audioChunks.current.push(event.data);
//       };
//       mediaRecorder.current.onstop = async () => {
//         const blob = new Blob(audioChunks.current, { type: "audio/wav" });
//         setAudioBlob(blob);
//         audioChunks.current = [];
//         await handleSubtitles(blob);
//       };
//     }
//   }, [isRecording]);

//   const startRecording = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         audio: true,
//       });
//       mediaRecorder.current = new MediaRecorder(stream);
//       mediaRecorder.current.start();
//       setIsRecording(true);
//       console.log("Recording started");
//     } catch (error) {
//       console.error("Error accessing microphone:", error);
//     }
//   };

//   const stopRecording = async () => {
//     if (mediaRecorder.current) {
//       mediaRecorder.current.stop();
//       setIsRecording(false);
//       console.log("Recording stopped");
//     } else {
//       console.error("No media recorder found");
//     }
//   };

//   const handleSubtitles = async (blob) => {
//     setIsLoading(true);
//     if (!blob) {
//       console.error("No audio recorded.");
//       setIsLoading(false);
//       return;
//     }

//     try {
//       const formData = new FormData();
//       formData.append("audio_file", blob, "audio.wav");

//       const response = await axios.post(
//         "http://localhost:4000/api/transcribe",
//         formData,
//         {
//           headers: {
//             accept: "application/json",
//             "Content-Type": "multipart/form-data",
//           },
//           withCredentials: true,
//         }
//       );

//       console.log(response);
//       setSubtitles(response.data);
//     } catch (error) {
//       console.error("Error uploading audio:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleFileUpload = async (event) => {
//     const files = event.target.files;
//     if (files.length > 0) {
//       const file = files[0];
//       const blob = new Blob([file], { type: file.type });
//       await handleSubtitles(blob);
//     } else {
//       console.error("No file selected.");
//     }
//   };

//   const handleSubmit = () => {
//     navigate("/");
//   };

//   return (
//     <Container>
//       <Navbar />
//       <Content>
//         <Sidebar>
//           <HeaderContainer>
//             <Logo src={logoo} alt="Logo" />
//             <ChatTitle>MithilaStackAI Chat</ChatTitle>
//           </HeaderContainer>
//           <Quote>History 1</Quote>
//           <Quote>History 2</Quote>
//         </Sidebar>
//         <MainContent>
//           <Header>
//             <h1>Mithila Stack AI Chat</h1>
//             <div>Your everyday AI companion</div>
//           </Header>

//           {isLoading ? (
//             <LoadingText>Loading...</LoadingText>
//           ) : subtitles ? (
//             <Transcript>
//               <h2>Transcript</h2>
//               <span>{subtitles}</span>
//             </Transcript>
//           ) : (
//             <StyledSlider {...settings}>
//               {photos.map((photo, index) => (
//                 <div key={index}>
//                   <img
//                     className="slider-image"
//                     src={photo.download_url}
//                     alt={photo.author}
//                   />
//                 </div>
//               ))}
//             </StyledSlider>
//           )}

//           <ChatBody>
//             <ChatBodyLeft>Speak Anything...</ChatBodyLeft>
//             <ChatBodyRight>
//               <Button
//                 isRecording={isRecording}
//                 onClick={isRecording ? stopRecording : startRecording}
//               >
//                 <LuMic />
//                 {isRecording ? "Stop Recording" : "Start Recording"}
//               </Button>

//               <Button>
//                 <FileInput
//                   type="file"
//                   id="file"
//                   className="file-input"
//                   accept="audio/*"
//                   onChange={handleFileUpload}
//                 />
//                 <FileInputLabel htmlFor="file">Choose a file</FileInputLabel>
//               </Button>
//             </ChatBodyRight>
//           </ChatBody>
//         </MainContent>
//       </Content>
//       <Footer />
//     </Container>
//   );
// };

// export default ChatAI;

import React, { useState, useEffect, useRef } from "react";
import { LuMic } from "react-icons/lu";
import styled, { css, keyframes } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logoo from "../../assets/mithilastack.svg";
import Navbar from "../Navbar";
import Footer from "../Footer"; // Make sure to import the Footer component
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ChatHistory from "../chatHistory/ChatHistory";
import { fetchChatHistory } from "../../utils/chatHistory";
import BASE_URL from "../../constant/url";

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

const Container = styled.div`
  /* max-width: 100; */
  width: 99.63%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (max-width: 768px) {
    height: auto;
    width: 100%;
    margin: 0;
    padding: 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 87.5%;
  }
`;

const Sidebar = styled.div`
  width: 20%;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 30%;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const Logo = styled.img`
  width: 30%;
  border-radius: 30px;
`;

const Quote = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #555;
  text-align: center;
`;

const MainContent = styled.div`
  width: 80%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const LoadingText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const StyledSlider = styled(Slider)`
  .slick-slide img {
    width: 60%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
    margin: 40px auto;
    padding: 10px;
    height: 400px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: transform 0.2s;
    animation: shimmer 1s infinite linear forwards;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 600px 104px;
  }

  .slick-slide img:hover {
    transform: scale(1.05);
  }
`;

const Transcript = styled.div`
  width: 50%;
  height: 300px;
  text-align: center;
  margin: 0 auto;
`;

const ChatBody = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
  width: 84%;
  margin: 20px auto;

  @media (max-width: 600px) {
    flex-direction: column;
    width: 90%;
  }
`;

const ChatBodyLeft = styled.div`
  flex: 3;
  padding: 10px;
`;

const ChatBodyRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding: 10px;

  @media (max-width: 600px) {
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    align-items: center;
  }
`;

const pulsate = css`
  @keyframes pulsate {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.7);
    }
    50% {
      box-shadow: 0 0 0 10px rgba(255, 99, 71, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 99, 71, 0.7);
    }
  }
`;

const Button = styled.button`
  animation: ${(props) =>
    props.isRecording ? `${pulsate} 2s infinite` : "none"};
  background-color: ${(props) => (props.isRecording ? "#FF6347" : "#fff")};
  padding: 0.7rem 1.5rem;
  border-radius: 30px;
  color: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: ${(props) => (props.isRecording ? "none" : "2px solid var(--black)")};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 1rem;
  margin-left: 1rem;
  z-index: 1;

  &:hover {
    transform: scale(1.1);
    background-color: ${(props) => (props.isRecording ? "#FF6347" : "#f0f0f0")};
  }

  &:focus {
    transform: scale(0.9);
  }

  svg {
    fill: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 10px;
    margin-left: 0px;
  }
`;

const PulseCircle = styled.div`
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite;
  z-index: -1;
`;

const FileInputLabel = styled.label`
  cursor: pointer;
  margin-left: 15px;
`;
const ResNav = styled.div`
  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

const FileInput = styled.input`
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  z-index: -1;
`;

const ChatTitle = styled.div`
  font-size: 11px; // Adjust the font size as needed
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const AIButton = styled.button`
  background-color: ${(props) => (props.isRecording ? "#FF6347" : "#fff")};
  padding: 0.7rem 1.5rem; /* Adjust padding as needed */
  border-radius: 30px;
  color: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  display: block; /* Changed from flex to block to allow width to be 100% */
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  border: ${(props) => (props.isRecording ? "none" : "2px solid var(--black)")};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 1rem;
  margin-left: 0; /* Adjusted to 0 to align with container's padding */
  z-index: 1;

  &:hover {
    transform: scale(0.9);
    background-color: ${(props) => (props.isRecording ? "#FF6347" : "#f0f0f0")};
  }

  &:focus {
    transform: scale(0.9);
  }

  svg {
    fill: ${(props) => (props.isRecording ? "var(--white)" : "var(--black)")};
  }

  @media (max-width: 600px) {
    margin-bottom: 10px;
    padding: 0.7rem; /* Adjust padding for smaller screens if necessary */
  }
`;

const ChatAI = () => {
  const [photos, setPhotos] = useState([]);
  const [chatData, setChatData] = useState([]);
  const [subtitles, setSubtitles] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const navigate = useNavigate();
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);

  useEffect(() => {
    async function getPhotos() {
      try {
        const response = await axios.get("https://picsum.photos/v2/list");
        setPhotos(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getPhotos();
  }, []);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    if (mediaRecorder.current) {
      mediaRecorder.current.ondataavailable = (event) => {
        audioChunks.current.push(event.data);
      };
      mediaRecorder.current.onstop = async () => {
        const blob = new Blob(audioChunks.current, { type: "audio/wav" });
        setAudioBlob(blob);
        audioChunks.current = [];
        await handleSubtitles(blob);
      };
    }
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.start();
      setIsRecording(true);
      console.log("Recording started");
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      console.log("Recording stopped");
    } else {
      console.error("No media recorder found");
    }
  };

  const handleSubtitles = async (blob) => {
    setIsLoading(true);
    if (!blob) {
      console.error("No audio recorded.");
      setIsLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("audio_file", blob, "audio.wav");

      const response = await axios.post(
        `${BASE_URL}/api/transcribe`,
        formData,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        }
      );

      console.log(response);

      fetchChatHistory(chatData, setChatData);

      setSubtitles(response.data);
    } catch (error) {
      console.error("Error uploading audio:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const blob = new Blob([file], { type: file.type });
      await handleSubtitles(blob);
    } else {
      console.error("No file selected.");
    }
  };

  const handleSubmit = () => {
    navigate("/");
  };

  console.log("chatData-->", chatData);

  return (
    <Container>
      <ResNav>
        <Navbar />
      </ResNav>
      <Content>
        <Sidebar>
          <HeaderContainer>
            <Logo src={logoo} alt="Logo" />
            <ChatTitle>MithilaStackAI Chat</ChatTitle>
          </HeaderContainer>
          <ChatHistory chatData={chatData} setChatData={setChatData} />
        </Sidebar>
        <MainContent>
          <Header>
            <h1>Mithila Stack AI Chat</h1>
            <div>Your everyday AI companion</div>
          </Header>

          {isLoading ? (
            <LoadingText>Loading...</LoadingText>
          ) : subtitles ? (
            <Transcript>
              <h2>Transcript</h2>
              <span>{subtitles}</span>
            </Transcript>
          ) : (
            <StyledSlider {...settings}>
              {photos.map((photo, index) => (
                <div key={index}>
                  <img
                    className="slider-image"
                    src={photo.download_url}
                    alt={photo.author}
                  />
                </div>
              ))}
            </StyledSlider>
          )}

          <ChatBody>
            <ChatBodyLeft>
              <AIButton
                isRecording={isRecording}
                onClick={isRecording ? stopRecording : startRecording}
              >
                <LuMic />
                {isRecording ? "Stop Recording" : "Start Recording"}
              </AIButton>
            </ChatBodyLeft>
            <ChatBodyRight>
              <Button>
                <FileInput
                  type="file"
                  id="file"
                  className="file-input"
                  accept="audio/*"
                  onChange={handleFileUpload}
                />
                <FileInputLabel htmlFor="file">Choose a file</FileInputLabel>
              </Button>
            </ChatBodyRight>
          </ChatBody>
        </MainContent>
      </Content>
      <Footer />
    </Container>
  );
};

export default ChatAI;
