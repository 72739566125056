// // import { StargateColors } from "../../utils/Colors";
// import { StargateColors } from "../../utils/Colors";
// import { Flex, Grid, GridItem, Heading } from "@chakra-ui/react";
// import React from "react";

// const Functions = () => {
//   const GridItemStyles = {
//     color: "white",
//     rounded: 32,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     w: "100%",
//     h: "100%",
//     minH: {
//       base: "150px",
//       md: "300px",
//     },
//     transition: "all 0.25s ease",
//     _hover: {
//       shadow: "md",
//     },
//   };

//   return (
//     <Flex
//       id="functions"
//       direction={"column"}
//       justify={"center"}
//       align={"center"}
//       my={24}
//       px={2}
//       maxW={1200}
//       mx={"auto"}
//     >
//       <Grid
//         templateRows="repeat(3, 1fr)"
//         templateColumns={{
//           base: "repeat(2, 1fr)",
//           md: "repeat(3, 1fr)",
//         }}
//         gap={4}
//         w={"100%"}
//       >
//         <GridItem
//           colSpan={2}
//           rowSpan={1}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/M7zriZy.png)"}
//           bgColor={StargateColors.lightGrey}
//           bgSize={"contain"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"center"}
//         ></GridItem>
//         <GridItem
//           colSpan={1}
//           rowSpan={1}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/MQEVBRb.png)"}
//           bgSize={"cover"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"center"}
//         ></GridItem>
//         <GridItem
//           colSpan={1}
//           rowSpan={{
//             base: 1,
//             md: 2,
//           }}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/9Zphp8f.png)"}
//           bgSize={"contain"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"bottom"}
//           bgColor={"#282c34"}
//         ></GridItem>
//         <GridItem
//           colSpan={1}
//           rowSpan={1}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/dhalAZP.png)"}
//           bgSize={"contain"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"center"}
//           bgColor={"#70115C"}
//         ></GridItem>
//         <GridItem
//           colSpan={1}
//           rowSpan={1}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/gwFRoJ8.png)"}
//           bgSize={"cover"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"center"}
//           bgColor={"#262626"}
//         ></GridItem>
//         <GridItem
//           colSpan={2}
//           rowSpan={1}
//           {...GridItemStyles}
//           bgImage={"url(https://i.imgur.com/qLIEuPn.png)"}
//           bgSize={"contain"}
//           bgRepeat={"no-repeat"}
//           bgPosition={"center"}
//           bgColor={StargateColors.lightGrey}
//         ></GridItem>
//       </Grid>
//     </Flex>
//   );
// };

// export default Functions;
import React from "react";
import styled from "styled-components";
import { StargateColors } from "../../utils/Colors";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 6rem 0;
  padding: 0 0.5rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const GridItem = styled.div`
  color: white;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s ease;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 768px) {
    min-height: 300px;
  }
`;

const LastGridItem = styled(GridItem)`
  @media (max-width: 767px) {
    grid-column: span 2;

  }
`;

const Functions = () => {
  return (
    <FlexContainer id="functions">
      <GridContainer>
        <GridItem
          style={{
            gridColumn: "span 2",
            backgroundImage: "url(https://i.imgur.com/M7zriZy.png)",
            backgroundColor: StargateColors.lightGrey,
          }}
        ></GridItem>
        <GridItem
          style={{
            backgroundImage: "url(https://i.imgur.com/MQEVBRb.png)",
            backgroundSize: "cover",
          }}
        ></GridItem>
        <GridItem
          style={{
            gridRow: "span 2",
            backgroundImage: "url(https://i.imgur.com/9Zphp8f.png)",
            backgroundColor: "#282c34",
            backgroundPosition: "bottom",
          }}
        ></GridItem>
        <GridItem
           style={{
            backgroundImage: "url(https://i.imgur.com/dhalAZP.png)",
            backgroundColor: "#70115C",
          }}
        
        ></GridItem>
        <LastGridItem
        
           style={{
            backgroundImage: "url(https://i.imgur.com/gwFRoJ8.png)",
            backgroundColor: "#262626",
            backgroundSize: "cover",
          }}
        ></LastGridItem>
        <GridItem
          style={{
            gridColumn: "span 2",
            backgroundImage: "url(https://i.imgur.com/qLIEuPn.png)",
            backgroundColor: StargateColors.lightGrey,
          }}
        ></GridItem>
      </GridContainer>
    </FlexContainer>
  );
};

export default Functions;
