import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

const MobileMenuContainer = styled.nav`
  display: ${({ clicked }) => (clicked ? "flex" : "none")};

  @media only screen and (max-width: 48em) {
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #374a59;
    padding: 1rem;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    a {
      display: block;
      color: var(--white);
      font-weight: 600;
      margin-bottom: 1rem;
      text-decoration: none;
    }
  }
`;

const Button = styled.button`
  background-color: var(--purple);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: var(--white);
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;

const MobileMenu = ({ clicked, handleMenuClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleCareersClick = (e) => {
    handleMenuClick("careers", e);
    navigate("/Careers");
  };

  return (
    <MobileMenuContainer clicked={clicked}>
      <a
        href="#home"
        onClick={(e) => {
          handleMenuClick("home", e);
        }}
      >
        Home
      </a>
      
      
      <a
        href="#services"
        onClick={(e) => {
          handleMenuClick("services", e);
        }}
      >
        Services
      </a>
      
     
      <a href="#careers" onClick={(e) => handleCareersClick(e)}>
        Features
      </a>
      {!isLoading && (
        <>
          <Button>Signup</Button>
        </>
      )}
    </MobileMenuContainer>
  );
};

export default MobileMenu;
